/* eslint-disable import/prefer-default-export */

import type { AxiosResponse } from 'axios';
import axios from 'axios';
import Cookies from 'js-cookie';

import endpointUrl from 'apis/endpoint';
import { ApiError, UnknownError } from 'errors';

/**
 * Interface for the admin report list response.
 */
interface AdminReportListResponseData {
    // Define the shape of the data you expect here.
    // For example:
    reports: Array<{
        id: number;
        title: string;
    }>;
}

/**
 * Interface for the admin user.
 */
export interface AdminUser {
    id: number;
    email: string;
    password: string;
}

/**
 * 管理者レポートリストの取得
 * @description 管理者用のレポートリストを取得する
 * @returns
 */
export const fetchAdminReportList = async (): Promise<AdminReportListResponseData> => {
    try {
        const res: AxiosResponse<AdminReportListResponseData> = await axios.get(endpointUrl(`/admin/report/list`));
        const data = res.data || { reports: [] };
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new ApiError('Fetch failed');
        } else {
            throw new UnknownError('Unknown Error');
        }
    }
};

/**
 * Interface for the admin login check response.
 */
interface CheckAdminLoginResponseData {
    admin_token: string;
}

/**
 * 管理者ログインチェック
 * @description 管理者のログイン状態をチェックする
 * @returns boolean ログイン状態（true: ログイン中, false: 未ログイン）
 */
export const checkAdminLogin = async (): Promise<boolean> => {
    try {
        const cookieAdminToken = Cookies.get('admin_token');
        if (!cookieAdminToken) {
            return false;
        }
        const res: AxiosResponse<CheckAdminLoginResponseData> = await axios.get(endpointUrl('/admin/login/check'));
        return res.data.admin_token === cookieAdminToken;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new ApiError('Login check failed');
        } else {
            throw new UnknownError('Unknown Error');
        }
    }
};

/**
 * Interface for the admin login response.
 */
interface AdminLoginResponseData {
    success: boolean;
    admin_token: string;
}

export const adminLogin = async (org: string, email: string, password: string): Promise<boolean> => {
    try {
        const response: AxiosResponse<AdminLoginResponseData> = await axios.post(endpointUrl('/admin/login'), { org, email, password });
        if (response.status === 200 && response.data.success) {
            Cookies.set('admin_token', response.data.admin_token, { secure: true, sameSite: 'strict' });
            return true;
        } 
        return false;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new ApiError('Admin login failed');
        } else {
            throw new UnknownError('Unknown Error');
        }
    }
};

/**
 * Interface for the admin logout response.
 */
interface AdminLogoutResponseData {
    success: boolean;
}

/**
 * 管理者ログアウト
 * @description 管理者をログアウトし、関連するクッキーを削除する
 * @returns Promise<boolean> ログアウト成功時はtrue、失敗時はfalse
 */
export const adminLogout = async (): Promise<boolean> => {
    try {
        const response: AxiosResponse<AdminLogoutResponseData> = await axios.get(endpointUrl('/admin/logout'));
        if (response.status === 200 && response.data.success) {
            Cookies.remove('admin_token'); // Corrected 'adminToken' to 'admin_token' to match the set cookie
            return true;
        }
        return false;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new ApiError('Admin logout failed');
        } else {
            throw new UnknownError('Unknown Error');
        }
    }
    return false;
};

/**
 * ユーザーを取得する
 * @returns ユーザーのリスト
 */
export const fetchUsers = async (): Promise<AdminUser[]> => { // Changed User to AdminUser
    try {
        const res: AxiosResponse<AdminUser[]> = await axios.get(endpointUrl('/admin/users/list')); // Changed User to AdminUser
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new ApiError('Fetch users failed');
        } else {
            throw new UnknownError('Unknown Error');
        }
    }
};

/**
 * ユーザーを削除する
 * @param userId ユーザーのID
 * @returns 成功した場合はtrue、失敗した場合はfalse
 */
export const deleteUser = async (userId: number): Promise<boolean> => {
    try {
        const res: AxiosResponse<{ success: boolean }> = await axios.delete(endpointUrl(`/admin/users/delete/${userId}`));
        return res.data.success;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new ApiError('Delete user failed');
        } else {
            throw new UnknownError('Unknown Error');
        }
    }
};

// 新しい addUser 関数を追加
export const addUser = async (email: string): Promise<AdminUser> => {
    try {
        const res: AxiosResponse<AdminUser> = await axios.post(endpointUrl('/admin/users/add'), { email });
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new ApiError('Add user failed');
        } else {
            throw new UnknownError('Unknown Error');
        }
    }
};