/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

type Props = {
    onNext: () => void;
};

const Step1 = ( props: Props ) => {
    const { onNext } = props;

    return (
        <>
            <div className="mb-4 text-center">
                <Image src={`${ process.env.PUBLIC_URL }/logo.png`} rounded className="h-auto" style={{ width: '300px' }} />
            </div>
            <p className="text-center mb-4">本面接サービスについてご説明をさせていただきます。<br />（個人情報や画面の操作方法について）</p>
            <Button variant="primary" onClick={onNext} className="mx-auto d-block">次へ</Button>
        </>
    );
};

export default Step1;
