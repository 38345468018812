import Cookies from 'js-cookie';
import React, { useState, useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';
import Row from 'react-bootstrap/Row';

import Question from 'components/atoms/Question';


type Props = {
    onNext: () => void;
};

const Step4 = ({ onNext }: Props) => {
    const [recordedVideoURL, setRecordedVideoURL] = useState<string | null>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const aiVideoRef = useRef<HTMLVideoElement>(null);
    const confirmVideoRef = useRef<HTMLVideoElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const chunksRef = useRef<Blob[]>([]);
    const timerRef = useRef<number | null>(null);

    useEffect(() => {
      const meetId = Cookies.get('meet_id');
      if (!meetId) {
        window.location.reload();
      }
    }, []);

    const setupMediaRecorder = (stream: MediaStream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                chunksRef.current.push(event.data);
            }
        };

        mediaRecorder.onstop = () => {
            const blob = new Blob(chunksRef.current, { type: 'video/webm' });
            if (blob.size > 0) {
                const url = URL.createObjectURL(blob);
                setRecordedVideoURL(url);
            } else {
                setRecordedVideoURL(null);
            }
            chunksRef.current = [];
        };
    };

    useEffect(() => {
        let stream: MediaStream | null = null;

        const startWebcam = async () => {
            try {
                stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
                setupMediaRecorder(stream);
            } catch (err) {
                console.error("Error accessing the webcam:", err);
            }
        };

        startWebcam().catch((err) => {
            console.error("Failed to start webcam:", err);
        });

        return () => {
            if (stream) {
                const tracks = stream.getTracks();
                tracks.forEach(track => track.stop());
            }
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    const handleAiVideoEnded = () => {};

    return (
        <>
            <Row className='mb-4'>
                <Col>
                    <Ratio aspectRatio="16x9">
                        <video 
                            ref={aiVideoRef}
                            autoPlay 
                            onEnded={handleAiVideoEnded}
                            aria-hidden="true" 
                            id="ai_staff"
                        >
			    <source src={`${process.env.REACT_APP_API_ENDPOINT}/static/mp4s/question1/5_1.mp4.webm`} type="video/webm"/>
                            <track kind="captions" />
                            Your browser does not support the video tag.
                        </video>
                    </Ratio>
                </Col>
                <Col style={{ display: 'none' }}>
                    <Ratio aspectRatio="16x9">
                        <video ref={videoRef} autoPlay playsInline muted aria-hidden="true" id="preview_webcamera">
                            <track kind="captions" />
                            Your browser does not support the video tag.
                        </video>
                    </Ratio>
                </Col>
            </Row>
            <Question title="" question="それでは、面接をはじめましょう。準備ができたら「面接を開始する」を押してください"/>

            <Button 
                variant="primary" 
                onClick={onNext} 
                className="mx-auto d-block"
            >
                面接を開始する
            </Button>
        </>
    );
};

export default Step4;
