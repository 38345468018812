/* eslint-disable jsx-a11y/control-has-associated-label */

import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import Image from 'react-bootstrap/Image';

const Step6 = () => {
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const finishInterview = async () => {
            const meetId = Cookies.get('meet_id');
            if (!meetId) {
                setError('面接IDが見つかりません。もう一度お試しください。');
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/api/admin/finish?mid=${meetId}`, {
                    method: 'GET',
                    headers: {'Content-Type': 'application/json',},
                });

                if (!response.ok) {
                    throw new Error('面接の終了に失敗しました');
                }

                const data = await response.json();
            } catch (err) {
                setError('面接の終了中にエラーが発生しました。もう一度お試しください。');
            }
        };

        finishInterview();
    }, []);

    if (error) {
        return <p className="text-center mb-4 text-danger">{error}</p>;
    }

    return (
        <>
            <div className="mb-4 text-center">
                <Image src={`${process.env.PUBLIC_URL}/logo.png`} rounded className="w-25 h-auto" />
            </div>
            <div className="d-flex justify-content-center">
                <p className="mb-0">
                    面接は以上で終了です。<br />企業からの連絡をお待ちください。
                    <br />
                    このまま画面を閉じてください。
                    <br />
                    受験いただきありがとうございました。
                </p>
            </div>
        </>
    );
};

export default Step6;
