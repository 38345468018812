/* eslint-disable jsx-a11y/control-has-associated-label */

import type { ReactNode } from 'react';
import React from 'react';

type Props = {
    children: ReactNode
};

const Main = ( props: Props ) => {
    const { children } = props;
    return (
        <div id="primary">
            {children}
        </div>
    )
};

export default Main;
