/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';

const AdminHeader = () => (
    <Navbar expand="md" sticky="top" className="bg-body mb-4 mb-md-5">
        <Container>
            <Navbar.Brand href="#/admin">
                    <Image
                        src={`${ process.env.PUBLIC_URL }/logo2.png`}
                        width="30"
                        style={{marginTop: '5px', marginRight: '5px'}}
                        className="d-inline-block align-top"
                    />{' '}
                    Track AI Interview (管理画面)
            </Navbar.Brand>
        </Container>
    </Navbar>
);

export default AdminHeader;
