import axios, { AxiosResponse } from 'axios';

import { fetchQuestions, fetchAdminReportList } from 'apis';
import endpointUrl from 'apis/endpoint';
import { InvalidDataError, UnknownError } from 'errors';

interface CheckMeetIdResponse {
  valid: boolean;
  message: string;
}

export const adminReportListFetchData = async (
  setAdminReportListData: (data: any) => void,
) => {
  try {
    const adminReportListData = await fetchAdminReportList();
    setAdminReportListData(adminReportListData);
  } catch (error) {
    throw new InvalidDataError('Content is not successfully fetched.');
  }
};

export const questionsFetchData = async (
  setQuestionsData: (data: any) => void,
) => {
  try {
    const questionsData = await fetchQuestions();
    setQuestionsData(questionsData);
  } catch (error) {
    throw new InvalidDataError('Content is not successfully fetched.');
  }
};

export const checkMeetId = async (meetId: string): Promise<boolean> => {
  try {
    const res: AxiosResponse<CheckMeetIdResponse> = await axios.get(
      endpointUrl(`/api/meet/check`),
      { params: { meet_id: meetId } }
    );
    return res.data.valid;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('AxiosError:', error.response?.status, error.response?.data);
      return false;
    } else {
      console.error(error);
      throw new UnknownError('Unknown Error');
    }
  }
};
