import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();

    useEffect(() => {
        const extractMidFromUrl = (url: string) => {
            const match = url.match(/[?&]mid=([^&#]*)/i);
            return match ? match[1] : null;
        };

        const mid = extractMidFromUrl(window.location.href);

        if (mid) {
            const expirationDate = new Date();
            expirationDate.setDate(expirationDate.getDate() + 2);
            document.cookie = `meet_id=${mid}; expires=${expirationDate.toUTCString()}; path=/`;
        }
    }, [location]);

    return (
        <Navbar expand="md" sticky="top" className="bg-body mb-4 mb-md-5">
            <Container>
                <Navbar.Brand href="#home">
                    <Image
                        src={`${process.env.PUBLIC_URL}/logo2.png`}
                        width="30"
                        style={{marginTop: '5px', marginRight: '5px'}}
                        className="d-inline-block align-top"
                    />{' '}
                    Track AI Interview
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Header;
