import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import { useNavigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';

import { checkAdminLogin } from 'apis/fetch/admin';
import { adminLogout } from 'apis/fetch/admin';
import Tab from 'components/molecules/Tab';
import AdminHeader from 'components/organisms/AdminHeader';
import AdminUserManager from 'components/organisms/AdminUserManager';
import CreateInterview from 'components/organisms/CreateInterview';
import Main from 'components/organisms/Main';
import { adminReportListFetchData } from 'utils/api';

interface DataItem {
  video_url: string;
  transcript: string;
  no: number;
  question: {
    video_path: string;
    no: string;
    subno: string;
    question: string;
    theme: string;
    subtheme: string;
  };
}

interface Interviewee {
  name: string;
  email: string;
  affiliation: string;
  status: string;
  status_txt: string;
}

interface Item {
  meet_id: string;
  ts: number;
  data: DataItem[];
  question_category: string[];
  summary: string;
  interviewee: Interviewee;
}

const Admin = () => {
  const [adminReportList, setAdminReportList] = useState<Item[]>([]);
  const [isCreatingInterview, setIsCreatingInterview] = useState(false);
  const [showUserManager, setShowUserManager] = useState(false);
  const navigate = useNavigate();
  const fetchData = async () => {
    try {
      await adminReportListFetchData(setAdminReportList);
    } catch (error) {
      console.error('Failed to fetch admin report list:', error);
    }
  };
  const handleLogout = async () => {
      await adminLogout();
      navigate('../login');
  };

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const isLoggedIn = await checkAdminLogin();
        if (!isLoggedIn) {
          navigate('../login');
        } else {
          fetchData();
        }
      } catch (error) {
        console.error('Failed to check admin login:', error);
        navigate('../login');
      }
    };

    checkLogin();
  }, [navigate]);

  const handleCreateInterview = () => {
    setIsCreatingInterview(true);
  };

  const handleShowUserManager = () => {
    navigate('users');
  };

  const handleBackToList = () => {
    setIsCreatingInterview(false);
    navigate('/');
  };

  return (
    <>
      <AdminHeader />
      <Container className="pb-md-5">
        <Main>
          <Routes>
            <Route path="users" element={<AdminUserManager />} />
            <Route path="/" element={
              <>
                <Row className="align-items-center mb-3">
                  <Col>
                    <div className="h4 mb-0">面談者一覧</div>
                  </Col>
                  <Col xs="auto">
                    <Button variant="primary" onClick={handleCreateInterview} className="me-2">
                      面談を作成
                    </Button>
                  </Col>
                  <Col xs="auto">
                    <Dropdown align="end">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        ⚙️
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={handleShowUserManager}>Adminユーザ管理</Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout}>ログアウト</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
                {isCreatingInterview ? (
                  <>
                    <Row className="align-items-center mb-3">
                      <Col>
                        <div className="h4 mb-0">面談を作成</div>
                      </Col>
                      <Col xs="auto">
                        <Button variant="secondary" onClick={handleBackToList}>
                          一覧に戻る
                        </Button>
                      </Col>
                    </Row>
                    <CreateInterview onClose={handleBackToList} />
                  </>
                ) : (
                  <Row className="justify-content-center">
                    <Col>
                      <Accordion className="row gy-3" alwaysOpen>
                        {adminReportList.map((item, index) => (
                          <Col xs="12" key={item.meet_id}>
                            <Accordion.Item eventKey={index.toString()}>
                              <Accordion.Header>{item.interviewee.name} ({item.interviewee.status_txt})</Accordion.Header>
                              <Accordion.Body>
                                <Tab meet_id={item.meet_id} data={item.data} question_category={item.question_category} summary={item.summary} interviewee={item.interviewee}/>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Col>
                        ))}
                      </Accordion>
                    </Col>
                  </Row>
                )}
              </>
            } />
          </Routes>
        </Main>
      </Container>
    </>
  );
};

export default Admin;
