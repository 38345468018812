export function getMediaRecorderOptions(): MediaRecorderOptions {
    const supportedTypes = [
        'video/mp4',
        'video/quicktime',
        'video/webm;codecs=h264',
        'video/webm',
    ];

    const mimeType = supportedTypes.find((type) => MediaRecorder.isTypeSupported(type));
    if (mimeType) {
        return { mimeType };
    }
    return {};
}

