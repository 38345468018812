/* eslint-disable jsx-a11y/control-has-associated-label */

import type React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import Notice from 'components/atoms/Notice';

type Props = {
    onNext: () => void;
};

const StyledOl = styled.ol`
    list-style-position: inside;
    list-style: none;
`;

const Step2 = ( props: Props ) => {
    const { onNext } = props;
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    return (
        <>
            <p className="mb-4">
「Track AI Interview（β版）」（以下、「本サービス」といいます）は、株式会社ギブリー（以下、「当社」といいます）が提供するオンライン面接サービスです。本サービスのご利用にあたっては、以下の利用規約（以下、「本規約」といいます）について同意いただく必要がありますので、内容をよくご確認ください。
            </p>
            <Notice>
                <StyledOl className="p-0 mb-0">
<li><small>第1条 （定義）</small></li>
<li><small>本規約において使用する用語は、以下の意味を有するものとします。</small></li>
<li><small>1 「受験者」とは、本サービスを利用してオンライン上で面接を受ける者を指します。</small></li>
<li><small>2 「録画録音データ」とは、受験者の面接中に録画録音された映像および音声データを指しま</small></li>
<li><small>す。</small></li>
<li><small>3 「会話ログ」とは、外部サービスのAPIを通じて音声認識モデルを使用し、録音データを文字</small></li>
<li><small>起こししたテキストデータを指します。</small></li>
<br/>
<li><small>第2条 （目的）</small></li>
<li><small>本規約は、受験者が本サービスを利用する際に遵守すべき事項および利用方法、利用条件等を</small></li>
<li><small>定めたものです。</small></li>
<br/>
<li><small>第3条 （規約の遵守）</small></li>
<li><small>1. 受験者は、本規約を理解し、その内容のすべてについて同意した上で本サービスを利用する</small></li>
<li><small>ものとし、また、本サービスの利用にあたっては、本規約を遵守しなければなりません。</small></li>
<li><small>2. 受験者が本サービスの利用を開始したときは、本規約を理解したうえで、その内容のすべて</small></li>
<li><small>に同意したものとみなされます。</small></li>
<li><small>3. 本規約に規定していない本サービスの利用条件の詳細については、当社が別途定めるもの</small></li>
<li><small>とします。</small></li>
<li><small>4. 当社が別途定める利用条件、規約、遵守事項など（以下、「利用条件等」といいます）も本規</small></li>
<li><small>約と同一の効力があるものとし、受験者は、本規約のほか当該利用条件等に従い、本サー</small></li>
<li><small>ビスを利用するものとします。</small></li>
<li><small>5. 本規約の内容と、前項の利用条件等における本サービスに関する説明等とが異なる場合に</small></li>
<li><small>は、本規約の規定が優先して適用されるものとします。</small></li>
<br/>
<li><small>第4条 （禁止事項）</small></li>
<li><small>1. 受験者は、本サービスの利用にあたり、以下の各号に該当する行為、または該当するおそ</small></li>
<li><small>れのある行為を自ら行ってはなりません。</small></li>
<li><small>1 第三者になりすまして、本サービスを利用する行為</small></li>
<li><small>2 法令に違反する行為または犯罪行為に関する行為</small></li>
<li><small>3 当社、本サービスの他の受験者、またはその他の第三者に対する詐欺または脅迫</small></li>
<li><small>行為</small></li>
<li><small>4 公序良俗に反する行為</small></li>
<li><small>5 当社、本サービスの他の受験者、またはその他の第三者の知的財産権、肖像権、プ</small></li>
<li><small>ライバシーの権利、名誉、その他の権利または利用を侵害する行為</small></li>
<li><small>6 本サービスを通じて以下に該当し、または該当すると当社が判断する情報を当社ま</small></li>
<li><small>たは本サービスの他の受験者に送信すること</small></li>
<li><small>・過度に暴力的または残虐な表現を含む情報</small></li>
<li><small>・コンピューターウイルスその他の有害なコンピュータープログラムを含む情報</small></li>
<li><small>・当社、本サービスの他の受験者、またはその他の第三者の名誉・信用を毀損する表現を</small></li>
<li><small>含む情報</small></li>
<li><small>・過度にわいせつな表現を含む情報</small></li>
<li><small>・差別を助長する表現を含む情報</small></li>
<li><small>・自殺、自傷行為を助長する表現を含む情報</small></li>
<li><small>・薬物の不適切な利用を助長する表現を含む情報</small></li>
<li><small>・反社会的な表現を含む情報</small></li>
<li><small>・チェーンメール等の第三者への情報の拡散を求める情報</small></li>
<li><small>・他人に不快感を与える表現を含む情報</small></li>
<li><small>7 本サービスのネットワークまたはシステム等に過度に負荷をかける行為</small></li>
<li><small>8 本サービスの運営を妨害するおそれのある行為</small></li>
<li><small>9 当社のネットワークまたはシステム等に不正にアクセスし、または不正なアクセスを</small></li>
<li><small>試みる行為</small></li>
<li><small>10 当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</small></li>
<li><small>11 本サービスの他の受験者の情報の収集</small></li>
<li><small>12 当社、本サービスの他の第三者に不利益、損害、不快感を与える行為</small></li>
<li><small>13 反社会的勢力等への利益供与、反社会的勢力等との交流または関与</small></li>
<li><small>14 面識のない異性との出会いを目的とした行為</small></li>
<li><small>15 前各号の行為を直接または間接に惹起し、または容易にする行為</small></li>
<li><small>16 その他、当社が不適切と判断する行為</small></li>
<li><small>2. 受験者が前項の禁止事項に違反したことにより、当社もしくは当社の顧客、他の受験者その</small></li>
<li><small>他の第三者に損害が発生した場合、受験者は、その一切の損害（弁護士費用、当社または</small></li>
<li><small>本サービスの信用、イメージ等の回復に要する費用等を含む）を当社に賠償する責を負うも</small></li>
<li><small>のとします。</small></li>
<br/>
<li><small>第5条 （権利帰属）</small></li>
<li><small>1. 本サービスに関する知的財産権はすべて当社または当社にライセンスを承諾している者に</small></li>
<li><small>帰属しており、当社または当社にライセンスを承諾している者を除き、方法または形態の如</small></li>
<li><small>何を問わず、これを当社に無断で複製、複写、転載、転送、蓄積、販売、出版その他本サー</small></li>
<li><small>ビスの利用契約の範囲を超えて使用してはならないものとします。</small></li>
<li><small>2. 当社による事前の書面による承諾を得ることなく、いかなる手段によるものを問わず、本サー</small></li>
<li><small>ビスを通じて行なった面接の内容の開示、漏えいまたは複製・転載を禁じます。</small></li>
<br/>
<li><small>第6条 （本サービスの利用停止等）</small></li>
<li><small>1. 当社は、以下のいずれかに該当する場合には、受験者に事前に通知することなく、本サービ</small></li>
<li><small>スの全部または一部の提供を停止または中断することができるものとします。</small></li>
<li><small>1 本サービスに係るコンピュータシステムの点検または保守作業を緊急で行う場合</small></li>
<li><small>2 コンピュータ、通信回線等が事故等により停止した場合</small></li>
<li><small>3 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運</small></li>
<li><small>営ができなくなった場合</small></li>
<li><small>4 その他、当社が停止または中断を必要と判断した場合</small></li>
<li><small>2. 当社は、本条に基づき当社が行った措置により受験者に生じた損害について一切の責任を</small></li>
<li><small>負いません。</small></li>
<br/>
<li><small>第7条 （保証の否認及び免責）</small></li>
<li><small>1. 当社は、本サービスを現状のまま提供するものとし、本サービスが受験者の特定の目的に</small></li>
<li><small>適合すること、期待する機能・商品的価値・正確性・有用性を有すること（録画録音データお</small></li>
<li><small>よび会話ログの適切性、解析結果の正確性を含むがこれに限られません）、受験者による本</small></li>
<li><small>サービスの利用が受験者に適用のある法令または業界団体の内部規約等に適合すること、</small></li>
<li><small>および不具合が生じないことについて、何ら保証するものではありません。</small></li>
<li><small>2. 当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、受験者</small></li>
<li><small>が本サービスに提供した録画録音データ及び会話ログの削除または消失、機器の故障もしく</small></li>
<li><small>は損傷、その他本サービスに関して受験者が被った損害については、当社の故意または重</small></li>
<li><small>過失による場合を除き、一切の責任を負わないものとします。</small></li>
<br/>
<li><small>第8条 （機密保持）</small></li>
<li><small>受験者は、本サービスに関連して知った当社の一切の情報（以下、「秘密情報」といいます）につ</small></li>
<li><small>いて、当社の事前の書面による承諾がある場合を除き、第三者に開示または漏えいしてはならな</small></li>
<li><small>いものとします。ただし、次の各号のいずれかに該当する情報についてはこの限りではありませ</small></li>
<li><small>ん。</small></li>
<li><small>1 提供を受けたとき、既に公知であった情報</small></li>
<li><small>2 提供を受けた後、公知となった情報</small></li>
<li><small>3 機密保持義務を負うことなく、既に保有している情報</small></li>
<li><small>4 提供を受けた後、機密保持義務を負うことなく第三者から正当に入手した情報</small></li>
<li><small>5 提供を受けた情報によらず、独自に開発した情報</small></li>
<br/>
<li><small>第9条 （個人情報および録画録音データ、会話ログの取扱い）</small></li>
<li><small>1. 当社は、本サービスを通じて、次の各号の個人情報を取得するものとし、受験者は予めこれ</small></li>
<li><small>を承諾するものとします。</small></li>
<li><small>1 面接回答中の録画録音データ</small></li>
<li><small>2. 当社は、本サービスを通じて、前項で取得した個人情報を次の各号の目的で利用するものと</small></li>
<li><small>し、受験者は予めこれを承諾するものとします。</small></li>
<li><small>1 当社での選考における面接評価及び評価レポートの作成</small></li>
<li><small>2 外部サービスの音声認識モデルを使用した会話ログの作成</small></li>
<li><small>3 本サービスの提供・維持・改善</small></li>
<li><small>4 受験者の本サービス利用のサポート</small></li>
<li><small>5 その他本サービスを含む当社が単独で行うサービスの運営に必要な範囲</small></li>
<li><small>3. 受験者は、当社のサービスを通じて提供する録画録音データ及び会話ログについて、その</small></li>
<li><small>著作物に関するすべての権利（著作権法第27条および第28条に定める権利を含みます）</small></li>
<li><small>を、提供時に、当社に対し無償で譲渡するものとし、これを予め承諾するものとします。受験</small></li>
<li><small>者は、当社または当社の指定する者に対し、著作者人格権を行使しないことに同意するもの</small></li>
<li><small>とします。</small></li>
<li><small>4. 当社は、本サービスに係る業務の全部または一部を委託した第三者（以下、「委託先」といい</small></li>
<li><small>ます）に対し、委託先との間で予め個人情報及び機密情報の保護についての契約を締結し</small></li>
<li><small>た上で、委託の目的を達成するために必要な限度で、機密情報及び個人情報を含む受験者</small></li>
<li><small>に関する情報を委託先に対して提供することができるものとし、受験者は予めこれを承諾す</small></li>
<li><small>るものとします。</small></li>
<li><small>5. 前各項に定めるほか、当社が本サービスを通じて取得した情報の取扱いについては、当社</small></li>
<li><small>が別途定める「プライバシーポリシー(https://givery.co.jp/privacy-policy/)」に従うものとしま</small></li>
<li><small>す。受験者は、本サービスの利用に先立ち、必ず「プライバシーポリシー</small></li>
<li><small>(https://givery.co.jp/privacy-policy/)」を読み、十分に内容をご確認いただいたうえで、本</small></li>
<li><small>サービスをご利用するものとします。</small></li>
<br/>
<li><small>第10条 （本規約の変更）</small></li>
<li><small>当社は、本規約を予告なく任意に変更できるものとします。受験者は、本サービスの利用に際し、</small></li>
<li><small>最新の本規約の内容を確認するものとし、受験者が本サービスを利用した場合には、当該受験</small></li>
<li><small>者は、本規約の変更に同意したものとみなします。なお、本規約に基づいて現に発生している権</small></li>
<li><small>利義務は、変更後の本規約に特段の定めがない限り、当該変更後の本規約の影響を受けないも</small></li>
<li><small>のとします。</small></li>
<br/>
<li><small>第11条 （分離可能性）</small></li>
<li><small>本規約のいずれかの条項またはその一部が、理由の如何を問わず、無効または執行不能と判断</small></li>
<li><small>された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定</small></li>
<li><small>の残りの部分は、継続して完全に効力を有するものとします。また、残存部分について、当該条項</small></li>
<li><small>もしくは部分の趣旨に最も近い有効な条項を無効な条項もしくは部分と置き換えて適用し、または</small></li>
<li><small>当該条項もしくは部分の趣旨に最も近い有効な条項となるよう合理的な解釈を加えて適用しま</small></li>
<li><small>す。</small></li>
<br/>
<li><small>第12条 （準拠法および管轄裁判所）</small></li>
<li><small>本規約および利用契約の準拠法は日本法とし、本規約または利用契約に起因し、または関連す</small></li>
<li><small>る一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</small></li>
<br/>
<li><small>２０２４年９月１日制定</small></li>
                </StyledOl>
            </Notice>
            <div className="mb-4 d-flex justify-content-center">
                <Form.Check
                    type="checkbox"
                    id="approve-term-of-use"
                    label="上記について同意します"
                    className="mx-auto"
                    onChange={handleCheckboxChange}
                />
            </div>
            <Button variant="primary" onClick={onNext} className="mx-auto d-block" disabled={!isChecked}>次へ</Button>
        </>
    );
};

export default Step2;
