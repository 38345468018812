/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    children: ReactNode;
}

const NoticeContent = styled.div`
    background-color: var(--bs-light);
    margin-bottom: 1.5rem;
    border-radius: 0.25rem;
    padding: 1rem;
    @media screen and (max-width: 768px) {
        background-color: var(--bs-white);
    }
`;

const Notice = ( props: Props ) => {
    const { children } = props;
    return (
        <NoticeContent className="text-muted">
            {children}
        </NoticeContent>
    );
}

export default Notice;
