import type { Ref } from 'react';
import { forwardRef } from 'react';
import * as icons from 'react-bootstrap-icons';

interface IconProps extends icons.IconProps {
    // Cannot use "name" as it is a valid SVG attribute
    // "iconName", "filename", "icon" will do it instead
    iconName: keyof typeof icons;
    className?: string;
}

const Icon = forwardRef((props: IconProps, ref: Ref<HTMLSpanElement>) => {
    const { iconName, className, ...restProps } = props;
    const BootstrapIcon = icons[iconName];

    return (
        <span ref={ref} className={className}>
            <BootstrapIcon {...restProps} style={{ position: 'relative', top: '-1.5px' }} />
        </span>
    );
});

export default Icon;

// https://www.npmjs.com/package/react-bootstrap-icons から拝借
