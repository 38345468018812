/* eslint-disable jsx-a11y/control-has-associated-label */

import React from 'react';
import styled from 'styled-components';

interface Props {
    title: string;
    question: string;
}

const Content = styled.div`
    padding: 1rem;
    background-color:var(--bs-light);
    @media screen and (max-width: 768px) {
        padding: 0.5rem;
        padding-top: 1rem;
        background-color:var(--bs-white);
    }
`;

const Label = styled.small`
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: var(--bs-primary);
    color: var(--bs-white);
    border-radius: 0.25rem;
    padding: 0 0.5rem;
    font-size: 0.875em;
`;

const Question = ( props: Props ) => {
    const { title, question } = props;
    return (
        <div className="mb-4 position-relative">
            <Label>
                {title}
            </Label>
            <Content className="rounded p-3 text-muted mb-0 text-small">
                <small>
                    {question}
                </small>
            </Content>
        </div>
    );
}

export default Question;
