import type { AxiosResponse } from 'axios';
import axios from 'axios';

import endpointUrl from 'apis/endpoint';
import { UnknownError } from 'errors';


interface CheckMeetIdResponse {
  valid: boolean;
  message: string;
}

export const checkMeetId = async (meetId: string): Promise<boolean> => {
  try {
    const res: AxiosResponse<CheckMeetIdResponse> = await axios.get(
      endpointUrl(`/api/meet/check`),
      { params: { meet_id: meetId } }
    );
    return res.data.valid;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return false;
    } else {
      throw new UnknownError('Unknown Error');
    }
  }
};
