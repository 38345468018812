/* eslint-disable max-classes-per-file */

export class ApiError extends Error {}

export class AuthenticationError extends ApiError {}
export class UnknownError extends ApiError {}

// バグでしか出現しえないもの
export class ProgramError extends Error {}

// 正しく無い値を検知した場合
export class InvalidDataError extends Error {}
