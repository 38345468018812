import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useSearchParams } from 'react-router-dom'; // React Router v6 以降
import styled from 'styled-components';

import Header from 'components/organisms/Header';
import Main from 'components/organisms/Main';
import Step1 from 'components/organisms/Step1';
import Step2 from 'components/organisms/Step2';
import Step3 from 'components/organisms/Step3';
import Step4 from 'components/organisms/Step4';
import Step5 from 'components/organisms/Step5';
import Step6 from 'components/organisms/Step6';

const ContentLayout = styled.div`
    background-color: var(--bs-white);
    border-radius: 1.5rem;
    padding: 3rem;
    @media screen and (max-width: 768px) {
        background-color: var(--bs-light);
        padding: 0;
        padding-bottom: 1.5rem;
        border-radius: 0;
    }
`;

const Interview = () => {
    const [searchParams] = useSearchParams();
    const [currentStep, setCurrentStep] = useState(1);

    useEffect(() => {
        const stepFromUrl = searchParams.get('step');
        if (stepFromUrl) {
            const parsedStep = parseInt(stepFromUrl, 10);
            if (!Number.isNaN(parsedStep) && parsedStep > 0) {
                setCurrentStep(parsedStep);
            }
        }
    }, [searchParams]);

    const renderScreen = () => {
        switch (currentStep) {
        case 1:
            return (
                <>
                    <h1 className="h4 mb-3">はじめに</h1>
                    <ContentLayout>
                        <Step1 onNext={() => setCurrentStep(2)} />
                    </ContentLayout>
                </>
            );
        case 2:
            return (
                <>
                    <h1 className="h4 mb-3">「Track AI Interview（β版）」受験者利用規約</h1>
                    <ContentLayout>
                        <Step2 onNext={() => setCurrentStep(3)} />
                    </ContentLayout>
                </>
            );
        case 3:
            return (
                <>
                    <h1 className="h4 mb-3">テスト質問</h1>
                    <ContentLayout>
                        <Step3 onNext={() => setCurrentStep(4)} />
                    </ContentLayout>
                </>
            );
        case 4:
            return (
                <>
                    <h1 className="h4 mb-3">面接を開始する</h1>
                    <ContentLayout>
                        <Step4 onNext={() => setCurrentStep(5)} />
                    </ContentLayout>
                </>
            );
        case 5:
            return (
                <>
                    <h1 className="h4 mb-3">面接</h1>
                    <ContentLayout>
                        <Step5 onNext={() => setCurrentStep(6)} />
                    </ContentLayout>
                </>
            );
        case 6:
            return (
                <>
                    <h1 className="h4 mb-3">面接終了</h1>
                    <ContentLayout>
                        <Step6 />
                    </ContentLayout>
                </>
            );
        default:
            return null;
        }
    };
    return (
        <>
            <Header />
            <Container className="pb-md-5">
                <Main>
                    <Row className="justify-content-center">
                        <Col md={10} lg={8}>
                            {renderScreen()}
                        </Col>
                    </Row>
                </Main>
            </Container>
        </>
    );
};

export default Interview;
